@import 'variables';

html,
body {
  height: 100%;
  overflow: hidden;
  font-size: 16px;
}

body {
  margin: 0;
  overflow: hidden;
}

pre {
  padding: 0 $padding;
}

mat-sidenav {
  box-shadow: none !important;
}

.mat-tab-label {
  width: 100%;
}

h4 {
  margin: 0;
  padding: $padding;
}

.padding {
  padding: $padding;
}
